.loader {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;

  &.fullScreen {
    position: absolute;
    height: calc(100%);
  }

  .loaderIcon {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
  }
}

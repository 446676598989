@import "../../styles/mixins";

.mobileDrawer {
  top: 5.75rem;
  position: absolute;
  width: 100%;
  height: calc(100% - 5.75rem);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 8;

  .navigationItems {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 10rem;

    @include bp(xs) {
      padding-bottom: 16rem;
    }

    .navigationItem {
      display: flex;
      width: 100%;
      align-items: center;
      cursor: pointer;
      font-size: 1.375rem;
      line-height: 2.063;
      font-weight: 600;

      &:first-child {
        margin-top: 3.5rem;
      }
    }
  }

  .waveIcon {
    width: 100%;
    margin-top: -4.25rem;

    @include bp(xs) {
      margin-top: -8.25rem;
    }

    @include bp(sm) {
      margin-top: -10.25rem;
    }

    svg {
      overflow: hidden;
    }
  }

  .buttonContainer {
    margin-left: auto;
    margin-right: auto;
    margin-top: -3rem;

    @include bp(xs) {
      margin-top: -6rem;
    }

    @include bp(s) {
      margin-top: -8rem;
    }

    @include bp(sm) {
      margin-top: -10rem;
    }
  }
}

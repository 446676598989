@import "../../styles/mixins";
@import "../../styles/variables";

.footer {
  margin-top: -4rem;
  position: relative;

  @include bp(xxs) {
    margin-top: -5rem;
  }

  @include bp(xs) {
    margin-top: -6rem;
  }

  @include bp(s) {
    margin-top: -8rem;
  }

  @include bp(sm) {
    margin-top: -11rem;
  }

  @include bp(m) {
    margin-top: -15rem;
  }

  @include bp(ml) {
    margin-top: -18rem;
  }

  @include bp(l) {
    margin-top: -21rem;
  }

  @include bp(xl) {
    margin-top: -25rem;
  }

  @include bp(xxxl) {
    margin-top: -30rem;
  }

  .footerContainer {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 8;
    position: relative;

    @include bp(l) {
      height: 21.875rem;
      padding-left: 4.375rem;
      padding-right: 4.375rem;
    }

    @include bp(l) {
      height: 25rem;
    }

    @include bp(xxxl) {
      height: 30rem;
    }

    .footerContent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include bp(l) {
        flex-direction: row;
      }

      .footerInfo {
        text-align: center;

        @include bp(l) {
          text-align: left;
        }

        .siteName {
          font-family: $fontFamilyPoppins;
          font-weight: 600;
          margin-bottom: 1.5rem;
          font-size: 1.25rem;
          line-height: 1.875rem;

          @include bp(l) {
            font-size: 2rem;
            line-height: 3rem;
            margin-bottom: 3rem;
          }
        }

        .infoLine {
          font-family: $fontFamilyPoppins;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1.313rem;
          margin-top: 0.5rem;

          &:first-child {
            margin-top: 0;
          }

          @include bp(l) {
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }

        .socialIcons {
          margin-top: 1.5rem;
          display: flex;
          gap: 1rem;
          justify-content: center;

          @include bp(l) {
            margin-top: 2.125rem;
            justify-content: flex-start;
          }
        }
      }

      .footerItemsWithInput {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        @include bp(l) {
          flex-direction: column;
          align-items: flex-end;
        }

        .footerItems {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1rem;

          @include bp(l) {
            flex-direction: row;
            align-items: flex-start;
            gap: 2rem;
          }

          .footerLink {
            font-family: $fontFamilyPoppins;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.5rem;

            @include bp(l) {
              font-size: 1.25rem;
              line-height: 1.875rem;
            }
          }
        }
      }
    }

    .copyrightContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 1rem;
      margin-top: 5.5rem;
      gap: 1rem;

      @include bp(l) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }

      .copyrightItem {
        font-family: $fontFamilyPoppins;
        font-weight: 400;
        font-size: 0.875rem;
        display: block;
        text-align: center;
      }
    }
  }
}

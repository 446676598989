@import "../../styles/mixins";
@import "../../styles/variables";

.buttonContainer {
  height: 3.688rem;
  width: fit-content;
  box-shadow: none;
  border: none;
  border-radius: 1.25rem;
  font-family: $fontFamilyPoppins;
  font-weight: 600;
  font-size: 1rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  cursor: pointer;
}

@import "../../styles/mixins";
@import "../../styles/variables";

.navigationContainer {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  z-index: 8;
  justify-content: space-between;
  width: 100%;
  height: 5.75rem;

  @include bp (m) {
    height: 98px;
    padding: 0 4.375rem;
  }

  .siteName {
    font-family: $fontFamilyPoppins;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.875rem;

    @include bp(m) {
      font-size: 2.125rem;
      line-height: 3.1875rem;
    }
  }

  .navigationItems {
    display: flex;
    align-items: center;
    gap: 32px;

    .navigationItem {
      font-size: 1rem;
      font-family: $fontFamilyPoppins;
      line-height: 1.5rem;
      font-weight: 600;
    }
  }
}

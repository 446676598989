@import "../../styles/mixins";
@import "../../styles/variables";

.label {
  height: 2rem;
  min-width: 1.375rem;
  line-height: 0;
  border-radius: 1rem;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 1.25rem 2rem;
  font-size: 1rem;
  font-family: $fontFamilyPoppins;
  font-weight: 700;

  &.success {
    color: rgb(34, 154, 22);
    background-color: rgba(84, 214, 44, 0.16);
  }
}

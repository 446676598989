@import "src/styles/mixins";
@import "src/styles/variables";

.emailInputContainer {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.125rem;
  border-radius: 1.25rem;
  width: 100%;
  max-width: 25rem;
  padding-left: 1.625rem;

  @include bp(l) {
    height: 3.75rem;
    margin-top: 3rem;
  }

  .inputContainer {
    display: flex;
    align-items: center;
    gap: 0.875rem;
    width: 100%;
    padding-right: 1.625rem;

    input {
      width: 100%;
      border: none;
      font-family: $fontFamilyPoppins;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      outline: none;
    }

    .icon {
      display: flex;
      align-items: center;

      > div {
        display: flex;
        align-items: center;
      }
    }
  }

  .button {
    height: 3.325rem;

    @include bp(l) {
      height: 3.95rem;
    }
  }
}
